import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"

export default ({data}) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <SEO pageTitle={post.frontmatter.title} />
      <Header pageTitle="Blog" bgImage="backgrounds/header.jpg" />
      <section className="blog post">
				<div className="container">	
          <Link className="back" to="/blog"><i class="fas fa-arrow-left"></i>Back to all posts</Link>
					<h2>{post.frontmatter.title}</h2>
          <div className="date">{post.frontmatter.date}</div>
          <Img className="floated-image framed-image" fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
					<div className="body-text" dangerouslySetInnerHTML={{ __html: post.html }} />
				</div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
				thumbnail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`